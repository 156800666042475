.app-icon {
    width: 160px;
    height: auto;
}

.github-icon {
    width: 40px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;

}

.link-container {
    display: block;
}

.link-container a {
    white-space: nowrap;
    transition: background-color 0.3s;
    display: flow;
}

.link-container a:hover {
    background-color: #eee;
}

.underline {
    text-decoration: solid;
}

.emoji-enlarge {
    font-size: 30px;
    padding-left: 10px;
    padding-right: 6px;
    display: inline-block;
    line-height: 1.5em;
    vertical-align: sub;
  }
